<template>
    <div class="postContainer">
        <div class="navContainer"></div>
        <img class="logoBlack" src="@/assets/cl-logo-blog.png" @click="$emit('changeRoute', {name: 'home'})">
        <div class="postContent">
            <h1>{{post.title}}</h1>
            <h2>{{post.subtitle}}</h2>
            <!--<div class="postSingleImage" :style="{backgroundImage: 'url('+post.img+')'}"
                 @click="goToPost(index)"></div>-->
            <div class="postSingleImage" :style="{backgroundImage: 'url('+post.img+')'}"></div>
            <small>{{post.date}}</small>
            <p v-html="post.content"></p>
            <div class="btn animated" @click="$emit('changeRoute', {name: 'blog'})">
                <div></div>
                <span>Go back</span>
            </div>
        </div>
        <footerComp/>
    </div>
</template>

<script>
export default {
    mounted() {
        document.body.style.height = "auto"
        window.scrollTo(0, 0)
    },

    destroyed() {
        document.body.style.height = "1000vh"
    },

    computed: {
        post() {
            return this.$store.state.currentPost
        }
    }
}
</script>

<style lang="sass">
$color1: rgba(38, 70, 83, 1)
$color2: rgba(42, 157, 143, 1)
$color3: rgba(233, 196, 106, 1)
$color4: rgba(244, 162, 97, 1)
$color5: rgba(231, 111, 81, 1)

.postContainer
    #footer
        background-color: black !important
    .navContainer
        height: 120px
        width: 100vw
        position: fixed
        z-index: 150
        left: 0
        top: 0
        background: #fff
        @media (max-width: 1100px)
            height: 95px
    width: 100vw
    min-height: 100vh
    overflow: hidden
    height: auto
    background-color: #fff
    position: absolute
    left: 0
    top: 0
    .postContent
        h1, h2, small, p
            color: black
        padding-left: 0 !important
        padding-right: 0 !important
        padding-top: 180px !important
        .btn
            margin-left: 20vw
            @media (max-width: 1100px)
                margin-left: 20px
            box-shadow: inset 0px 0px 0px 2px black
            div
                background-color: black !important
            span
                color: black
            &:hover
                span
                    color: white
        .postSingleImage
            width: 100vw
            margin-top: 100px
            height: 30vw
            background-size: cover
            background-position: center
        h1
            opacity: 0
            transform: translateX(-100px)
            font-size: 70px
            @media (max-width: 1100px)
                font-size: 30px
            margin: 90px 0 10px 0
            animation: postTitleFadeLeft 1s 1.5s ease-in-out forwards
        h2 
            opacity: 0
            transform: translateX(-100px)
            margin: 0
            font-size: 30px !important
            @media (max-width: 1100px)
                font-size: 15px !important
                margin: 20px 10px 0 10px
            animation: postTitleFadeLeft 1s 1.5s ease-in-out forwards
        small, p
            margin: 0
            padding: 0 20vw
            @media (max-width: 1100px)
                padding: 20px
            text-align: left
            display: block
        p
            margin-bottom: 200px
            line-height: 1.5
            word-spacing: 5px
            letter-spacing: .8px
            @media (max-width: 1100px)
                margin-bottom: 100px
        small 
            margin: 100px 0 100px 0
            @media (max-width: 1100px)
                margin: 10px 0
            font-weight: bold
            font-style: italic
            font-size: 15px
        h2
            font-size: 30px
    .btn
        margin-top: 15px
        margin-left: 0
        width: 140px
        span
            text-align: center
        &:hover
            span
                color: #333

@keyframes postTitleFadeLeft
    0%
        opacity: 0
        transform: translateX(-100px)
    100%
        opacity: 1
        transform: translateX(0)
</style>